import React from "react";
import { NavLink } from "react-router-dom";

const Casestudy = () => {
  return (
    <div className="agiloft-page landing-page smart-study">
      <h2 className="text-center">Case Study</h2>
      <section className="stories-container" id="success-stories">
        <div className="row page-wrap">
          <NavLink
            to={`/customers/philips`}
            className="col-4 item"
          >
            <figure>
              <img
                src={`/img/smartsheet/customers/philips2.png`}
              />
            </figure>
            <div className="txt">
              <h3 className="font-weight600">Philips</h3>
              <span>Philips Accelerates Clinical Research</span>
            </div>
          </NavLink>
          <NavLink
            to={`/customers/zoom-brandfolder`}
            className="col-4 item"
          >
            <figure>
              <img
                src={`/img/smartsheet/customers/zoom2.png`}
                alt
              />
            </figure>
            <div className="txt">
              <h3 className="font-weight600">Zoom</h3>
              <span>Customer Success Stories</span>
            </div>
          </NavLink>
          <NavLink
            to={`/customers/HP`}
            className="col-4 item"
          >
            <figure>
              <img
                src={`/img/smartsheet/customers/hp.jpg`}
                alt
              />
            </figure>
            <div className="txt">
              <h3 className="font-weight600">HP</h3>
              <span>Smartsheet Skyrockets Sales</span>
            </div>
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default Casestudy;
