import React from "react";
import Whychooseus from "./Comp/Whychooseus";
import GartnerInsight from "./Comp/GartnerInsight";
import AboutVirtuos from "./Comp/AboutVirtuos";

const SmartsheetNew = () => {
  return (
    <div id="smartsheet">
      <GartnerInsight />
      <AboutVirtuos />
      <Whychooseus />
    </div>
  );
};

export default SmartsheetNew;
