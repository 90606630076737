import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../Navbar';
import Nav from '../Nav';
import Footer from '../Footer';

const Philips = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);
    const [activeItem, setActiveItem] = useState(null);

    const handleItemClick = (index) => {
        if (activeItem === index) {
            setActiveItem(null);
        } else {
            setActiveItem(index);
        }
    };

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };
    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Philips";

    }, []);
    return (
        <div className="client-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/smartsheet/customers/philips_banner.png`}
                                    alt="TVS Credit Banner"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/smartsheet/customers/2.png`}
                                        alt="TVS Credit Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Philips Accelerates Clinical Research with Smartsheet: A Leap Towards Enhanced Project Delivery

                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                {/* <h3 className="title">About TVS-Credit Services</h3> */}
                                <p>In a bid to expedite clinical research, Philips Clinical Operations has integrated Smartsheet, a comprehensive program management and collaboration tool. This strategic move aims to promote transparency, facilitate quicker project updates, and optimize workflows, ultimately leading to more efficient healthcare research.
                                    Revolutionizing Healthcare with Philips and Smartsheet: A Leap Forward in Clinical Operations
                                </p>
                                <p>
                                    For over a century, Philips has been at the forefront of healthcare innovation, with clinical trials serving as a pivotal catalyst for its scientific breakthroughs. According to Gemma Quinn, Manager of Clinical Operations at Philips, their primary focus lies in ensuring patient safety, maintaining quality, and upholding the integrity of all Philips-sponsored and supported studies. However, navigating through the complexities of each trial, which involves numerous stakeholders working collaboratively, presents its fair share of challenges.

                                </p>


                                <h3 className="title">Empowering Global Impact through Project-Level Enhancements
                                </h3>
                                <p>
                                    Addressing the need for a user-friendly software that caters to individuals with varying technical expertise, the Philips Clinical Operations team sought a versatile solution. Quinn emphasizes that adaptability to manage trials of diverse complexities and team sizes was a key requirement. Their pursuit led them to implement Smartsheet, an agile platform that not only bridged information gaps but also captured all tasks, projects, timelines, and resources in a centralized location.

                                </p>

                                <h5 className="title">Streamlining Project Launches</h5>
                                <p></p>

                                <h3 className="title"></h3>
                                <p>To establish consistency across projects, the team devised a comprehensive project toolkit, encompassing standard plans, time trackers, and dashboards based on global clinical processes. This toolkit evolved into a replicable blueprint, expediting the initiation of new trials. Upon project requests, the team quickly provisions in the Control Center, granting access to templates, reports, and forms, thus ensuring a fast, efficient, and consistent approach. Additionally, all studies are readily available through a unified portfolio dashboard, offering immediate insights into each study's current phase.
                                </p>
                                <p>Before commencing a new project, the project manager completes a Smartsheet request form. Once submitted, the form is saved into a Smartsheet Intake Sheet, triggering a prompt notification for the creation of a study folder in Smartsheet. This seamless process enables the team to swiftly proceed with the setup.
                                </p>
                                <p>Throughout different project stages, various Smartsheet forms come into play, enabling the collection of information from stakeholders across the globe, thereby facilitating faster task progress.
                                </p>

                                <h3 className="title">Gaining a Global and Local Perspective through Smartsheet
                                </h3>
                                <p>Leveraging Smartsheet's capabilities, Philips Clinical Operations acquires a comprehensive overview of each project. The team possesses the flexibility to delve into specific details when required, consistently pulling metrics from various tools and platforms to create an encompassing portfolio view. Additionally, interactive dashboards provide a deeper understanding of each project's status.
                                </p>
                                <p>Each team and project possesses dedicated sheets, featuring clear-cut status indications. The implementation of an intuitive traffic light system color-codes timely projects as green, delayed projects as orange, and significantly delayed ones as red. This efficient tracking mechanism ensures that potential concerns are promptly addressed.
                                </p>

                                <h3 className="title">Promoting Urgent Collaboration
                                </h3>
                                <p>Smartsheet proves instrumental in expediting clinical projects by fostering seamless collaboration among all stakeholders. Quinn expresses the desire to instill a unified approach to work. With sheets containing all necessary project information, the team now accesses critical data internally, eliminating the need for counterproductive email communication. Transparency is maintained by assigning individuals to specific project roles, and real-time communication transpires directly within the project context through the Conversations feature.
                                </p>

                                <h3 className="title">Effortless Project Progression</h3>
                                <p>Philips Clinical Operations ensures smooth project updates for stakeholders through the use of forms, catering to contributors who may not be comfortable working with sheets. An automated notification system prompts stakeholders to take action, providing direct links to website forms for their convenience. Once the forms are completed, all updates seamlessly populate into the respective sheets.
                                </p>
                                <p>Furthermore, Smartsheet's global view empowers stakeholders with insights into trial complexity, timeline, and dependencies. This level of transparency helps educate all functions on the significance of visible critical dependencies and the impact of task delays on overall project success.
                                </p>

                                <h3 className="title">Driving Innovation for a Healthier Future</h3>
                                <p>At present, Philips Clinical Operations relies extensively on Smartsheet for its business-critical projects, capitalizing on a unified resource pool and tools. To further enhance clinical trial planning and execution oversight, the team is piloting resource management and allocation settings, seeking a streamlined process for sourcing and assigning roles.
                                </p>
                                <figure className="blog-content-image offport-down">
                                    <img
                                        src={`/img/smartsheet/customers/philips_content.png`}
                                        alt="TVS Credit Case Study"
                                    />
                                    <span>
                                        The company's commitment to innovation remains steadfast, as it continuously explores new avenues for collaboration, communication, and metrics sharing to advance scientific research. Within this dynamic landscape, Smartsheet emerges as an integral part of Philips' Clinical Operations, offering essential capabilities for forecasting resources, tracking utilization, and enabling real-time program management. Ultimately, this translates to informed decision-making for management, ensuring the successful execution of clinical trials.

                                    </span>
                                </figure>

                            </div>
                        </div>
                    </section>

                    {/*?php include '../footer';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Philips
