import React, { useEffect, useRef } from "react";

const VideoComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as needed
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Video is in the viewport, so play it
          videoRef.current.play();
        } else {
          // Video is not in the viewport, so pause it
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const currentVideo = videoRef.current;
    if (currentVideo) {
      observer.observe(currentVideo);
    }

    return () => {
      if (currentVideo) {
        observer.unobserve(currentVideo);
      }
    };
  }, []);

  return (
    <figure className="video media-foreground paragraph" data-loop="loop-once">
      <video
        autoPlay
        muted
        // width={1350}
        // height={750}
        className="js-playing"
        playsInline
        controls={false} // Remove video controls
        onContextMenu={(e) => e.preventDefault()} // Prevent right-click context menu
        onDoubleClick={(e) => e.preventDefault()} // Prevent double-click behavior
        ref={videoRef}
      >
        <source
          src="https://www.smartsheet.com/sites/default/files/2022-05/Smartsheet-Integrations-Q2-2022.mp4"
          type="video/mp4"
        />
      </video>
    </figure>
  );
};

export default VideoComponent;
