import React from "react";
import Client from "./Client";
import { NavLink } from "react-router-dom";

const AboutVirtuos = () => {
  return (
    <div className=" crm-digital-page" id="smartsheet">
      <div className="canvas">
        <div className="">
          <section className="crm-digital-container ">
            <h5 className="sblue text-center">
              <b> About Virtuos</b>
              {/* <span className="highlight-red">IT’S HOT NOW</span> */}
            </h5>
            <span className="section-desc custom-container">
              At <strong className="font-weight-400">Virtuos Digital</strong>,
              we understand the importance of efficient project management.
              Discover the power of{" "}
              <strong className="font-weight-400">Smartsheet</strong>, the
              ultimate solution for streamlining workflows, collaborating with
              teams, and achieving unparalleled productivity. Take your business
              to new heights with our comprehensive Smartsheet implementation
              services.
            </span>
            <div className="abtlegacy custom-container-2 custom-flex-col-gap3">
              <div className="row pink-bg custom-flex-gap flex-col-rev mt-4" >
                <div className=" img-block">
                  <figure>
                    <img
                      src={`/img/smartsheet/about/project_portfolio.webp`}
                      alt="Sales"
                    />
                  </figure>
                </div>
                <div className=" content-block custom-flex-col custom-flex-col-gap1 ">
                  <h5> Streamline and simplify project portfolio execution</h5>
                  <p className="">
                    Find the optimal balance between top-down and bottom-up
                    portfolio management strategies to ensure project uniformity
                    while preserving user experience and fostering user-driven
                    innovation.
                  </p>
                  {/* <NavLink
                  to={`/crm-digital/sales/`}
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink> */}
                </div>
              </div>
              <div className="desktop">
                <div className="row custom-flex-gap pink-bg  flex-col-rev first-content2 ">
                  <div className="col-6 content-block custom-flex-col custom-flex-col-gap1">
                    <h5>
                      Collaborate seamlessly and improve real time visibility{" "}
                    </h5>
                    <p className="">
                      Unlock the power of seamless collaboration by breaking
                      down the siloes between teams & projects. Set tasks,
                      dependencies and automate workflows to get more done in
                      less time. Eliminate inefficiencies in process and the
                      guesswork on outcomes by leveraging real-time visibility
                      and dashboards.
                    </p>
                    {/* <NavLink
                    to={`/crm-digital/marketing/`}
                  >
                    Explore <i className="fa fa-long-arrow-right" />
                  </NavLink> */}
                  </div>
                  <div className="col-6 img-block">
                    <figure>
                      <img
                        src={`/img/smartsheet/about/about2.png`}
                        alt="Sales"
                      />
                    </figure>
                  </div>
                </div>
              </div>

              <div className="mobile">
                <div className="row flex-col-rev pink-bg ">
                  <div className="col-6 img-block">
                    <figure>
                      <img
                        src={`/img/smartsheet/about/about2.png`}
                        alt="Sales"
                      />
                    </figure>
                  </div>
                  <div className="col-6 content-block custom-flex-col custom-flex-col-gap1">
                    <h5>
                      COLLABORATE SEAMLESSLY AND IMPROVE REAL TIME VISIBILITY
                    </h5>
                    <p className="">
                      Unlock the power of seamless collaboration by breaking
                      down the siloes between teams & projects. Set tasks,
                      dependencies and automate workflows to get more done in
                      less time. Eliminate inefficiencies in process and the
                      guesswork on outcomes by leveraging real-time visibility
                      and dashboards.
                    </p>
                    {/* <NavLink
                    to={`/crm-digital/marketing/`}
                  >
                    Explore <i className="fa fa-long-arrow-right" />
                  </NavLink> */}
                  </div>
                </div>
              </div>

              {/* <div className="row first-content">
              <div className="col-6 content-block">
                <h5>Collaborate Seamlessly <br />with Smartsheet</h5>
                <p> Foster team collaboration and achieve project success with Smartsheet. Foster team collaboration and achieve project success with Smartsheet.
                </p>
                <NavLink
                  to={`/crm-digital/marketing/`}
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink>
              </div>
              <div className="col-6 img-block">
                <figure >
                  <img
                    src={`/img/smartsheet/about22.png`}
                    alt="Marketing"
                  />
                </figure>
              </div>
            </div> */}
              <div className="row custom-flex-gap flex-col-rev green-bg">
                <div className="col-6 img-block">
                  <figure>
                    <img
                      src={`/img/smartsheet/about/new-dashboard-UI.png`}
                      alt="Customer Service"
                    />
                  </figure>
                </div>
                <div className="col-6 content-block custom-flex-col custom-flex-col-gap1">
                  <h5> Drive Results with Smartsheet Reports</h5>
                  <p className="">
                    Discover the transformative potential of Smartsheet Reports
                    as they empower you with valuable, data-driven insights.
                    Make informed decisions and drive results by leveraging the
                    actionable information at your fingertips through our
                    intuitive reporting tools.
                  </p>
                  {/* <NavLink
                  to={`/crm-digital/service/`}
                >
                  Explore <i className="fa fa-long-arrow-right" />
                </NavLink> */}
                </div>
              </div>
            </div>
          </section>
          <Client />
        </div>
      </div>
    </div>
  );
};

export default AboutVirtuos;
