import React from "react";
import { NavLink } from "react-router-dom";
// import TrySmartsheetNow from './TrySmartsheetNow'

const Purchase = () => {
  return (
    <div id="smartsheet">
      <section className="wow fadeIn padding-60px-tb sm-padding-40px-tb bg-started">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 center-col position-relative text-center custom-flex-gap">
              <span className="text-extra-large text-white alt-font display-inline-block  xs-no-margin-right xs-no-margin-top xs-margin-15px-bottom xs-width-100">
                Ready to get started with Smartsheet?
              </span>
              <NavLink
                to={
                  "https://app.smartsheet.com/b/form/e8a0bdfcc92245baae58df237fe5d5ac"
                }
                target="_blank"
                className="btn btn-white btn-medium vertical-align-top xs-no-margin-right btn-rounded"
              >
                {/* <TrySmartsheetNow /> */}
                {/* <i className="fas fa-shopping-cart" /> */}
                Book a Demo
              </NavLink>
              {/* <span className="text-white"> 
                |
              </span> */}
            
              <NavLink
                to={
                  "https://app.smartsheet.com/b/form/e8a0bdfcc92245baae58df237fe5d5ac"
                }
                target="_blank"
                className="btn btn-white btn-medium vertical-align-top xs-no-margin-right btn-rounded"
              >
                {/* <TrySmartsheetNow /> */}
                {/* <i className="fas fa-shopping-cart" /> */}
                Start Free Trial
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Purchase;
