import React from 'react'
import { NavLink } from "react-router-dom";
import ApplyForJob from './Forms/ApplyForJob';
const CommonApplyForJob = () => {
    return (
        <>
           <div className="button-wrapper manual-application">
                                
                                <ApplyForJob/>
                            </div>
                            {/* <div class="button-wrapper linkedin-application">
                        <NavLink to="#" class="btn btn-primary"><i class="fab fa-linkedin"></i> Apply with Linkedin</NavLink>
                    </div> */}
                            <div className="share-job">
                                <span>Share this job with friends</span>
                                <ul className="row a2a_kit a2a_kit_size_32 a2a_default_style">
                                    <li>
                                        <NavLink className="a2a_button_facebook">
                                            <i className="fab fa-facebook" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="a2a_button_twitter">
                                            <i className="fab fa-twitter" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="a2a_button_linkedin">
                                            <i className="fab fa-linkedin" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <a href='https://www.addtoany.com/add_to/email?linkurl=https%3A%2F%2Fwww.virtuos.com%2Fcareers%2Fjobs%2F.net-web-developer&linkname=Career%20Opportunities%20%7C%20Microsoft%20.Net%20Developer%20%E2%80%93%20Virtuos%20Jobs&linknote=.Net%20Development%3A%20The%20role%20also%20accords%20a%20deep%20understanding%20of%20Customer%27s%20business%20processes%2C%20and%20technology%20adaptation%20providing%20an%20ample%20room%20for%20candidate%27s%20overall%20growth' className="a2a_button_email">
                                            <i className="fas fa-envelope" />
                                        </a>
                                    </li>
                                    <li>
                                        <NavLink className="a2a_button_whatsapp">
                                            <i className="fab fa-whatsapp" />
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="careers-side-menu">
                                <NavLink to="/careers/">
                                    <i className="fa fa-home" /> Careers
                                </NavLink>
                                <NavLink to={`/careers/opportunities/`}>
                                    JOB OPPORTUNITIES
                                </NavLink>
                                <NavLink to={`/careers/experience-job/`}>
                                    EXPERIENCEJOB
                                </NavLink>
                                <NavLink to={`/careers/culture/`}>
                                    CULTURE &amp; VALUES
                                </NavLink>
                                <NavLink to={`/careers/life-at-virtuos/`}>
                                    LIFE AT VIRTUOS
                                </NavLink>
                                <NavLink to={`/careers/employee-benefits/`}>
                                    EMPLOYEE BENEFITS
                                </NavLink>
                                <NavLink to={`/careers/360Outlook/`}>
                                    360<sup>0</sup> OUTLOOK
                                </NavLink>
                                <NavLink to={`/careers/happiests-culture/`}>
                                    HAPPIESTS CULTURE
                                </NavLink>
                                <NavLink to={`/careers/virtuos-alumni/`}>
                                    VIRTUOS ALUMNI
                                </NavLink>
                                <NavLink to={`/careers/jobs/`}>SEE OTHER POSTS</NavLink>
                            </div>
        </>
    )
}

export default CommonApplyForJob
