import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../Navbar';
import Nav from '../Nav';
import Footer from '../Footer';

const HP = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);
    const [activeItem, setActiveItem] = useState(null);

    const handleItemClick = (index) => {
        if (activeItem === index) {
            setActiveItem(null);
        } else {
            setActiveItem(index);
        }
    };

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };
    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Virtuos Customer Success Stories | TVS Credit CRM case study – Virtuos Digital";

    }, []);
    return (
        <div className="client-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/smartsheet/customers/hp_banner.png`}
                                    alt="TVS Credit Banner"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/smartsheet/customers/1.png`}
                                        alt="TVS Credit Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Smartsheet Skyrockets Sales: 6 to 26 Events/Quarter

                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                {/* <h3 className="title">About TVS-Credit Services</h3> */}
                                <p>By implementing Smartsheet for sales and marketing event management, the HP North America Commercial Marketing team achieved a remarkable 90% reduction in email communication for sales targeted events. Through the elimination of planning and status meetings, Roxie Palacios successfully escalated the number of HP-led events from 6 to an impressive 26 per quarter. Furthermore, the event turnaround time witnessed a significant improvement, decreasing from 84 days to just 54 days. Smartsheet's efficient solutions paved the way for enhanced productivity and streamlined processes, resulting in greater success and increased customer satisfaction for HP.
                                </p>

                                <h4 ><b>Case Study: </b>HP North America Commercial Marketing Revolutionizes Event Management with Smartsheet  </h4>
                                <h4><b>Introduction: </b>HP - A Global Leader in Technology Solutions
                                </h4>
                                <p>HP, a renowned provider of products, technologies, software, solutions, and services, has been a trusted name in the tech industry since its establishment in 1939. Specializing in PCs, printers, and 3D printing solutions, HP serves people and businesses worldwide with innovative and reliable technology.
                                </p>

                                <h3 className="title">Challenges Faced by HP North America Commercial Marketing.</h3>
                                <p>
                                    HP's North America Commercial Marketing group plays a crucial role in supporting the organization's sales and marketing teams across the globe. Managing a multitude of meetings, webinars, conferences, and various other events each year, the Marketing group faced significant challenges in maintaining efficiency and accuracy.
                                </p>
                                <p>Before adopting Smartsheet, the Marketing team relied on different project management and calendar tools, leading to manual processes, duplicate data, and inefficient communication. Roxie Palacios, Senior Events Manager, spent countless hours each week manually updating project tracking applications. Coordinating tasks and scheduling meetings required multiple emails, leading to communication bottlenecks and project delays.
                                </p>

                                <h3 className="title">Discovering Smartsheet: A Game-Changer for HP</h3>
                                <p>Smartsheet emerged as a game-changer for the HP North America Commercial Marketing group. As Roxie Palacios shares, "Smartsheet is everything that I wanted in a project management system. It was something I'd always wanted but never knew existed."
                                </p>

                                <h3 className="title">Streamlining Event Management with Smartsheet</h3>
                                <p>With Smartsheet, the Marketing group found the automation and integration they needed to transform their event management processes. The team leveraged Smartsheet to track and manage all events effectively. The Smartsheet Calendar App provided real-time, updated information on sales and marketing events, empowering the team with clear visibility.
                                </p>


                                <h3 className="title">Benefits of Smartsheet Implementation</h3>
                                <p>Smartsheet's implementation led to remarkable results for the HP North America Commercial Marketing group:
                                </p>

                                <ul>
                                    <li><b>Increased Event Frequency: </b>The team increased the number of new events per quarter from 6 to an impressive 26, empowering them to engage with their audience more frequently.
                                    </li>
                                    <li><b>Faster Turnaround Time: </b>Event planning turnaround time improved significantly, reducing from an average of 84 days to just 54 days, enhancing agility and responsiveness.
                                    </li>
                                    <li><b>Revenue Impact: </b>The efficiencies gained through Smartsheet's automation and tracking contributed directly to revenue gains. The Marketing group achieved impressive returns on investment, positively impacting the bottom line.
                                    </li>
                                </ul>

                                <h3 className="title">Impressive Results and Growth
                                </h3>
                                <p>The implementation of Smartsheet brought about a myriad of benefits for the HP North America Commercial Marketing group:
                                </p>

                                <ul>
                                    <li><b>Automated Event Creation: </b>Smartsheet streamlined event creation, tracking, and updates. Collaborating with agencies to create new sales experience events triggered automatic notifications, minimizing the need for back-and-forth emails and fostering seamless coordination.
                                    </li>
                                    <li><b>Enhanced Efficiency: </b>Smartsheet significantly increased overall efficiency, reducing email communication by 90% for sales targeted events. The Smartsheet dashboard offered real-time updates on dates, content assets, and registrations, eliminating the need for extensive email communications.
                                    </li>
                                    <li><b>Reduced Meetings: </b>With improved project and event tracking, the Marketing group experienced a reduction in planning and status meetings, enabling more focused efforts on improving the customer experience.
                                    </li>
                                </ul>


                                <h3 className="title">Continuing Success with Smartsheet</h3>
                                <p>Recognizing the success with Smartsheet, the HP North America Commercial Marketing group plans to expand its usage across various teams, further enhancing organization, communication, and collaboration. As Virtuos Digital, a proud partner of Smartsheet, we are committed to empowering companies like HP with cutting-edge solutions to achieve unparalleled success in their endeavors. Smartsheet's transformative power continues to drive growth and innovation, delivering exceptional results for businesses worldwide.
                                </p>

                            </div>
                        </div>
                    </section>

                    {/*?php include '../footer';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default HP
