import { useState, useEffect, useRef } from "react";
// import baseUrl from "../../utils/baseUrl";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
// import exportIcon from "/img/csv_export.png";
// import pdfIcon from "/img/PDF_Icon.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import Image from "next/image";
import ExportOptionsModal from "./PDFModal";
// import ExportOptionsCsvModal from "./CSVModal";
const columns = [
  {
    name: "Name",
    selector: row => row.full_name,
    sortable: true,
    filter: "text",
  },
  {
    name: "Email",
    selector: row => row.email,
    sortable: true,
    filter: "text",
  },
  {
    name: "Phone",
    selector: row => row.mobile,
    sortable: true,
    filter: "text",
  },
  {
    name: "Subject",
    selector: row => row.subject,
    sortable: true,
    filter: "text",
  },
  {
    name: "Message",
    selector: row => row.message,
    sortable: true,
    filter: "text",
  },
  {
    name: "Date",
    selector: row => row.created,
    sortable: true,
    filter: "text",
    cell: (row) => row?.created.slice(0, 10),
  },
  {
    name: "Type",
    selector: row => row.type,
    sortable: true,
    filter: "text",
  },
  {
    name: "Page",
    selector: row => row.webURL,
    sortable: true,
    filter: "text",
  }
];

export default function LeadTable() {
  const [data, setData] = useState([]); // Initialize as an empty array
  const [searchText, setSearchText] = useState("");
  const [dataCSV, setDataCSV] = useState(null); // Initialize as an empty array

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExportModalCSVOpen, setIsExportModalCSVOpen] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("all");
  const [exportType, setExportType] = useState(null);
  const csvLinkRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/db`);
        const result = await response.json();
        console.log("Result");
        console.log(result);
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  console.log(data);
  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );
  console.log(filteredData);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // const csvData = filteredData?.map((item) => ({
  //   Name: item.username,
  //   Email: item.email,
  //   Phone: item.phone,
  //   Subject: item.subject,
  //   Message: item.message,
  //   Date: item.created_at,
  // }));

  const handleExport = (option) => {
    console.log(option);
    setSelectedExportOption(option);
    // closeExportModal();
    console.log(option);
    if (exportType === "PDF") {
      exportToPDF(option);
    }
    if (exportType === "CSV") {
      exportToCSV(option);
    }
  };

  const exportToPDF = (option) => {
    // console.log(option);
    setIsExportModalOpen(true);
    let filteredDataToExport = [];
    if (option === "lastMonth") {
      const lastMonth = new Date();
      console.log(lastMonth.getMonth());
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      // console.log(lastMonth);
      filteredDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastMonth
      );
    } else if (option === "last3Months") {
      const lastThreeMonths = new Date();
      lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);
      filteredDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastThreeMonths
      );
    } else if (option === "last6Months") {
      const lastSixMonths = new Date();
      lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);
      filteredDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastSixMonths
      );
    } else if (option === "all") {
      filteredDataToExport = filteredData;
    }

    console.log("FilteredDatatoExport ", filteredDataToExport);
    const bodyData = filteredDataToExport.map((item) =>
      columns.map((column) => {
        // Check if the selector is a function and call it with the row data
        const value = typeof column.selector === 'function' 
                      ? column.selector(item) 
                      : item[column.selector];
        
        // console.log(`Mapping for ${column.name}: `, value); // Debugging
        return value !== undefined && value !== null ? value : "N/A";
      })
    );

    const pdf = new jsPDF();
    pdf.text("Leads Data", 10, 10);
    pdf.autoTable({
      head: [columns.map((column) => column.name)],
      body: bodyData,
    });
    pdf.save("leads.pdf");
  };
  const exportToCSV = (option) => {
    console.log(option);
    setIsExportModalCSVOpen(true);
    let filteredCSVDataToExport = [];
    if (option === "lastMonth") {
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      filteredCSVDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastMonth
      );
    } else if (option === "last3Months") {
      const lastThreeMonths = new Date();
      lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);
      filteredCSVDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastThreeMonths
      );
    } else if (option === "last6Months") {
      const lastSixMonths = new Date();
      lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);
      filteredCSVDataToExport = filteredData.filter(
        (item) => new Date(item.created) > lastSixMonths
      );
    } else if (option === "all") {
      filteredCSVDataToExport = filteredData;
    }
    console.log(filteredCSVDataToExport);
    const csvData = filteredCSVDataToExport?.map((item) => ({
      Name: item.fullname,
      Email: item.email,
      Phone: item.mobile,
      Subject: item.subject,
      Message: item.message,
      Date: item.created,
    }));
    console.log(csvData);
    setDataCSV(csvData);
  };
  // console.log(dataCSV);
  const openPDFExportModal = () => {
    setExportType("PDF");
    setIsExportModalOpen(true);
  };

  const openCSVExportModal = () => {
    setExportType("CSV");
    setIsExportModalOpen(true);
  };
  //   const openExportModal = (type) => {
  //     setExportType(type);
  //     setIsExportModalOpen(true);
  //   };

  const closeExportModal = () => {
    setIsExportModalOpen(false);
    setIsExportModalCSVOpen(false);
  };

  useEffect(() => {
    if (csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  }, [dataCSV]);

  return (
    <div className="leads_table">
      <div className="search-bar-leads">
        <h1>Leads</h1>
        {data.length > 0 && (
          <div className="input_field">
            <div className="export_button">
              <p className="export_text">Export : &nbsp;</p>
              <img
                onClick={openCSVExportModal}
                src="/img/csv_export.png"
                height={30}
                width={30}
                alt=""
              />
              <p className="export_text">&nbsp; &nbsp;</p>

              {dataCSV && (
                <CSVLink
                  data={dataCSV}
                  filename={"leads.csv"}
                  ref={csvLinkRef}
                ></CSVLink>
              )}

              <img
                onClick={openPDFExportModal}
                src="/img/PDF_Icon.png"
                height={30}
                width={30}
                alt=""
              />
            </div>
            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearch}
            />
          </div>
        )}
      </div>

      <DataTable
        columns={columns}
        data={filteredData}
        noHeader
        defaultSortField="id"
        defaultSortAsc={true}
        pagination
        highlightOnHover
        dense
        // className="data-table-container"
      />
      {isExportModalOpen && (
        <ExportOptionsModal
          onClose={closeExportModal}
          onExport={handleExport}
          exportType={exportType}
        />
      )}
      {/* {isExportModalCSVOpen && (
        <ExportOptionsCsvModal
          onClose={closeExportModal}
          onExport={handleExport}
        />
      )} */}
    </div>
  );
}
