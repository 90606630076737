import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPenRuler } from "@fortawesome/free-solid-svg-icons";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

const Whychooseus = () => {
  return (
    <div id="smartsheet">
      <section className="whychoosebg wow fadeIn why_choose_us">
        <div className="container">
          <div className="row" style={{ marginLeft: "0" }}>
            <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12 center-col margin-eight-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
              <div className="alt-font text-white  margin-5px-bottom text-uppercase text-medium">
                <b> Why Choose Us?</b>
              </div>
              <h2 className=" text-white font-weight-600 ">
                Unleash the Power of Smartsheet
              </h2>
            </div>
          </div>

          <div className="grid">
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/automation.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Automation
                  </div>
                </div>
                <p className="hidden-text ">
                  Streamline tasks and workflows effortlessly with Smartsheet's
                  automation capabilities.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/team_collaboration.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Team Collaboration
                  </div>
                </div>
                <p className="hidden-text ">
                  Foster seamless collaboration within your team using
                  Smartsheet's collaborative features, enhancing communication
                  and productivity.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/dashboards_and_reportings.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Dashboards and reporting
                  </div>
                </div>
                <p className="hidden-text ">
                  Gain insights and track progress with intuitive dashboards and
                  robust reporting tools in Smartsheet.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/proofing.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Proofing
                  </div>
                </div>
                <p className="hidden-text ">
                  Ensure accuracy and quality with Smartsheet's proofing
                  feature, facilitating efficient review and approval processes.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/account_administration.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Account Administration
                  </div>
                </div>
                <p className="hidden-text ">
                  Easily manage and customize account settings to meet your
                  organizational needs with Smartsheet's comprehensive account
                  administration
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/Integrations.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Integrations
                  </div>
                </div>
                <p className="hidden-text ">
                  Seamlessly connect and enhance your workflow by integrating
                  Smartsheet with a variety of third-party tools and
                  applications.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/digital_asset_management.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Digital Asset Management
                  </div>
                </div>
                <p className="hidden-text ">
                  Organize and manage digital assets efficiently within
                  Smartsheet, providing a centralized hub for your resources.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/resource_management.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Resource management
                  </div>
                </div>
                <p className="hidden-text ">
                  Effectively allocate and manage resources with Smartsheet's
                  resource management tools, optimizing project efficiency.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/portfolio_management.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Portfolio management
                  </div>
                </div>
                <p className="hidden-text ">
                  Gain a holistic view of projects and portfolios, enabling
                  strategic decision-making and effective management of
                  resources.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/secure_request_management.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Secure Request Management
                  </div>
                </div>
                <p className="hidden-text ">
                  Ensure the security of requests and streamline the submission
                  process with Smartsheet's secure request management features.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/intelligent_workflows.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    Intelligent workflows
                  </div>
                </div>
                <p className="hidden-text ">
                  Enhance productivity and efficiency with Smartsheet's
                  intelligent workflows that automate and optimize your business
                  processes.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>
            <div className="card wow fadeInUp">
              <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
                <div className="custom-flex-gap-left">
                  <img
                    src={`/img/smartsheet/icons/no_code_work_apps.svg`}
                    style={{ color: "#086ad8" }}
                    alt
                  />

                  <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                    No code work apps
                  </div>
                </div>
                <p className="hidden-text ">
                  Build customized applications without coding, empowering users
                  to create tailored solutions to meet their specific needs with
                  Smartsheet's no-code work apps.
                </p>
                <div className="feature-box-overlay bg-blue-deep" />
              </div>
            </div>{" "}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whychooseus;
