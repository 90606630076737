import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const Client = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div className="prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
  const CustomNextArrow = ({ onClick }) => (
    <div className="next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  return (
    <section
      className="clients-container brands"
      style={{ overflow: "hidden" }}
    >
      <h5 className="section-main-heading" style={{ paddingInline: "1rem" }}>
        Trusted by your favorite brand and over 90% of <br />
        <span className="sblue">
          <b>FORTUNE 100</b>{" "}
        </span>
        COMPANIES.
      </h5>
      <div className="row custom-flex desktop-client ">
        <div className="tab-padding">
          <ul className="custom-flex-justify">
            <li className="li1">
              <img
                // src={`/img/svg/flipkart.svg`}
                src={`/img/smartsheet/companies/alaska_airlines.png`}
                alt="Flipkart - Virtuos Client"
              />
            </li>
            <li className="li2">
              <img
                // src={`/img/svg/standard_chartered.svg`}
                src={`/img/smartsheet/companies/american_airlines.png`}
                alt="Standard Chartered - Virtuos Client"
              />
            </li>
            <li className="li3">
              <img
                // src={`/img/svg/paytm-logo.svg`}
                src={`/img/smartsheet/companies/cisco.png`}
                alt="Paytm - Virtuos Client"
              />
            </li>
            <li className="li4">
              <img
                // src={`/img/svg/giftcart-logo.svg`}
                src={`/img/smartsheet/companies/dominos_logo.png`}
                alt="Giftcart - Virtuos Client"
              />
            </li>
            <li className="li5">
              <img
                // src={`/img/svg/make-my-trip-logo.svg`}
                src={`/img/smartsheet/companies/jpl.png`}
                alt="Make my trip - Virtuos Client"
              />
            </li>
            <li className="li6">
              <img
                // src={`/img/indiafirst-logo.jpg`}
                src={`/img/smartsheet/companies/kaiser.png`}
                alt="Indiafirst - Virtuos Client"
              />
            </li>
          </ul>
        </div>
      </div>

      {/* <ul className=""> */}
      <Slider {...settings} className=" bxslider smartslider mobile-client">
        <li className="li1">
          <img
            // src={`/img/svg/flipkart.svg`}
            src={`/img/smartsheet/companies/alaska_airlines.png`}
            alt="Flipkart - Virtuos Client"
          />
        </li>
        <li className="li2">
          <img
            // src={`/img/svg/standard_chartered.svg`}
            src={`/img/smartsheet/companies/american_airlines.png`}
            alt="Standard Chartered - Virtuos Client"
          />
        </li>
        <li className="li3">
          <img
            // src={`/img/svg/paytm-logo.svg`}
            src={`/img/smartsheet/companies/cisco.png`}
            alt="Paytm - Virtuos Client"
          />
        </li>
        <li className="li4">
          <img
            // src={`/img/svg/giftcart-logo.svg`}
            src={`/img/smartsheet/companies/dominos_logo.png`}
            alt="Giftcart - Virtuos Client"
          />
        </li>
        <li className="li5">
          <img
            // src={`/img/svg/make-my-trip-logo.svg`}
            src={`/img/smartsheet/companies/jpl.png`}
            alt="Make my trip - Virtuos Client"
          />
        </li>
        <li className="li6">
          <img
            // src={`/img/indiafirst-logo.jpg`}
            src={`/img/smartsheet/companies/kaiser.png`}
            alt="Indiafirst - Virtuos Client"
          />
        </li>
      </Slider>
      {/* </ul> */}
    </section>
  );
};

export default Client;
