import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Navbar from '../Navbar';
import Nav from '../Nav';
import Footer from '../Footer';

const Zoom = () => {
    const [showExtraLinks, setShowExtraLinks] = useState(false);
    const [activeItem, setActiveItem] = useState(null);

    const handleItemClick = (index) => {
        if (activeItem === index) {
            setActiveItem(null);
        } else {
            setActiveItem(index);
        }
    };

    const handleMouseEnter = () => {
        setShowExtraLinks(true);
    };
    const handleMouseLeave = () => {
        setShowExtraLinks(false);
    };
    useEffect(() => {
        document.title = "Zoom";

    }, []);
    return (
        <div className="client-detail-page landing-page">
            <div className="canvas">
                <Navbar />
                <main className="main-wrapper">
                    <section className="main-blogs case-study">
                        <div className="article-wrapper">
                            <figure className="article-image">
                                <img
                                    src={`/img/smartsheet/customers/zoom_banner.png`}
                                    alt="TVS Credit Banner"
                                />
                            </figure>
                            <div className="page-wrap article-info">
                                <span className="c-logo">
                                    <img
                                        src={`/img/smartsheet/customers/3.png`}
                                        alt="TVS Credit Logo"
                                    />
                                </span>
                                <div className="col-9 title-wrapper">
                                    <h2 className="article-heading">
                                        Customer Success Stories - Empowering Businesses with Smartsheet

                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="page-wrap row">
                            <div className="col-10 article-content">
                                {/* <h3 className="title">About TVS-Credit Services</h3> */}
                                <p>Welcome to Virtuos Digital, your trusted partner for digital solutions. As a proud reseller of Smartsheet, we are excited to share inspiring customer success stories that showcase the transformative power of this powerful work management platform. One such remarkable story is that of Zoom Video Communications, Inc., a leading communication platform that helps people stay connected, collaborate effectively, and achieve more together.

                                </p>


                                <h3 className="title">Zoom's Creative Team Saves Over 4,000 Hours with Brandfolder

                                </h3>
                                <h4><b>Challenge</b></h4>
                                <p>When the global pandemic led to a rapid rise in remote work, Zoom experienced unprecedented growth as one of the fastest-growing communication platforms. Amidst this surge, Zoom's creative team faced a significant challenge in managing their vast array of assets stored in multiple disorganized folders on Google Drive. Retrieving brand assets became time-consuming and frustrating, with Zoom's creative manager, Brandon Realmonte, spending approximately 10 hours per week just searching for assets.

                                </p>

                                <h4><b>Solution</b></h4>
                                <p>Recognizing the need for a more efficient solution, Brandon discovered Brandfolder - a game-changer for Zoom's creative team. He presented the benefits to the Chief Marketing Officer, highlighting that Brandfolder could save up to 60% of the design resources' time by hosting and organizing all their assets in a centralized hub. The decision was made, and Brandfolder became Zoom's central repository for creative assets and final designs.
                                    <br />With thousands of approved, on-brand assets now accessible through Brandfolder, Zoom's stakeholders - over 6,000 strong across 243 countries and territories - can easily find everything they need to create websites, email campaigns, and other marketing materials.

                                </p>

                                <h3 className="title">Simplifying Asset Management with Smart CDN</h3>
                                <p>Brandfolder's Smart CDN proved to be a powerful tool for Zoom, enabling simplified and accelerated email localization and webpage development. With localized emails distributed across the globe, developers can quickly embed creative assets into web page mockups, streamlining the development process.
                                </p>

                                <h3 className="title">Empowering Zoom's Partners
                                </h3>
                                <p>Managing assets for an enterprise like Zoom can be a massive undertaking. Brandfolder's portals allow thousands of partners to access curated assets and self-serve, significantly reducing the team's workload. Now, internal employees love browsing Zoom's virtual backgrounds portal to pick their favorite Zoom background for the day. Brandfolder Templates enable the creation of virtual backgrounds for various occasions, fostering a culture of co-branding and creative storytelling.
                                </p>

                                <h3 className="title">Fostering a Culture of Brand Pride</h3>
                                <p>Having assets on Brandfolder holds a special significance at Zoom. It signifies company-wide approval and cultural pride in reaching that level of distribution. This empowered culture has led to widespread adoption and engagement with Brandfolder, making it the go-to platform for Zoom's assets.
                                </p>

                                <h3 className="title">Zoom Success Stats:</h3>
                                <ul>
                                    <li>Brandfolder saves Zoom's creative team 4,000+ hours every year, thanks to findability and self-service features.
                                    </li>
                                    <li>~2,700 assets and 20 Brandfolder Collections are centrally stored and accessed every day through Brandfolder.
                                        6,800+ Zoom employees around the world now have quick access to assets.
                                    </li>
                                    <li>20-30 vendors access assets through customized portals without any delay.
                                    </li>
                                    <li>3,000+ templates were created to streamline Zoom's workflow process.
                                    </li>
                                    <li>The team rolled out 4 robust product launches in 7 months, thanks to advanced search features based on product-specific verbiage.
                                    </li>
                                    <li>Their asset management went from chaos to organized nirvana in just a few weeks with a quick implementation window.
                                    </li>

                                </ul>
                                <h3 className="title">Join the Success Story with Virtuos Digital and Smartsheet

                                </h3>
                                <p>As a valued partner of Smartsheet, Virtuos Digital is committed to empowering your business with innovative digital solutions. With Smartsheet's versatile platform and our dedicated support, you too can streamline workflows, boost productivity, and achieve remarkable results. Let's create your success story together. Contact us today to embark on a transformative journey with Smartsheet.
                                </p>

                            </div>
                        </div>
                    </section>

                    {/*?php include '../footer';?*/}
                    <Footer />
                </main>

            </div>
            <div className="off-canvas">
                <Nav />
            </div>
        </div>
    )
}

export default Zoom
