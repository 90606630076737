import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPenRuler } from "@fortawesome/free-solid-svg-icons";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

const Whychooseus = () => {
  return (
    <section className="whychoosebg wow fadeIn why_choose_us">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12 center-col margin-eight-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
            <div className="alt-font text-white  margin-5px-bottom text-uppercase text-medium">
              <b> Why Choose Us?</b>
            </div>
            <h2 className=" text-white font-weight-600 ">
              Unleash the Power of
              <b> Smartsheet</b>
            </h2>
          </div>
        </div>

        <div className="grid">
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Automation
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Team Collaboration
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Dashboards and reporting
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Proofing
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Account Administration
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Integrations
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Digital Asset Management
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Resource management
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Portfolio management
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Secure Request Management
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  Intelligent workflows
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>
          <div className="card wow fadeInUp">
            <div className="bg-white box-shadow-light text-center padding-twelve-all feature-box-8 position-relative z-index-5 md-padding-six-all height-100 xs-padding-15px-lr">
              <div className="custom-flex-gap-left">
                <img
                  src={`/img/smartsheet/icons/boost_efficiency.png`}
                  style={{ color: "#086ad8" }}
                  alt
                />

                <div className="alt-font text-extra-dark-gray font-weight-600 text-large margin-10px-bottom">
                  No code work apps
                </div>
              </div>
              <p className="hidden-text ">
                Streamline processes and enhance productivity across your
                organization.
              </p>
              <div className="feature-box-overlay bg-blue-deep" />
            </div>
          </div>{" "}
        </div>
      </div>
    </section>
  );
};

export default Whychooseus;
