import React from "react";

const Percentage = () => {
  return (
    <section className="no-padding wow fadeIn xs-padding-30px-tb custom-container" id="smartsheet">
      <div className="container-fluid">
        <div className="row equalize feature-box-14">
          {/* start feature box item */}

          {/* end feature box */}
          {/* start feature box item */}
          <div
            className="col-md-4 col-sm-6 col-xs-12 text-center display-table last-paragraph-no-margin wow fadeInRight no-padding"
            data-wow-delay="0.2s"
          >
            <div className="display-table-cell vertical-align-top padding-fifteen-all xs-padding-seven-all">
              <h2 className="highlight-red alt-font letter-spacing-minus-3 no-margin-bottom font-weight-600">
                50%
              </h2>
              <span className="display-block alt-font text-large sblue margin-15px-top margin-10px-bottom">
                <b> 50% Efficiency Boost!</b>
              </span>
              <p className="text-medium">
                McGraw Hill witnessed a significant 50% increase in efficiency
                as they automated and simplified their processes.
              </p>
            </div>
          </div>
          {/* end feature box */}
          {/* start feature box item */}
          <div
            className="col-md-4 col-sm-6 col-xs-12 text-center display-table last-paragraph-no-margin wow fadeInRight no-padding"
            data-wow-delay="0.4s"
          >
            <div className="display-table-cell vertical-align-top padding-fifteen-all xs-padding-seven-all">
              <h2 className="highlight-red alt-font letter-spacing-minus-3 no-margin-bottom font-weight-600">
                7x
              </h2>
              <span className="display-block alt-font text-large sblue margin-15px-top margin-10px-bottom">
                <b>7x Distribution Boost!</b>
              </span>
              <p className="text-medium">
                McLaren Racing experienced a remarkable 7x in the distribution
                of race day footage and assets by leveraging the power of
                Smartsheet and Brandfolder.
              </p>
            </div>
          </div>
          {/* end feature box */}
          {/* start feature box item */}
          <div
            className="col-md-4 col-sm-6 col-xs-12 text-center display-table last-paragraph-no-margin wow fadeInRight no-padding"
            data-wow-delay="0.6s"
          >
            <div className="display-table-cell vertical-align-top padding-fifteen-all xs-padding-seven-all">
              <h2 className="highlight-red alt-font letter-spacing-minus-3 no-margin-bottom font-weight-600">
                2x
              </h2>
              <span className="display-block alt-font text-large sblue margin-15px-top margin-10px-bottom">
                <b> 2x Productivity Boost</b>
              </span>
              <p className="text-medium">
                Uber achieved a remarkable 2x in productivity by reducing
                process time by 50%.
              </p>
            </div>
          </div>
          {/* end feature box */}
        </div>
      </div>
    </section>
  );
};

export default Percentage;
