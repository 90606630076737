import React from "react";
import { NavLink } from "react-router-dom";

const GartnerInsight = () => {
  return (
    <div id="smartsheet">
      <section className="cust-centric-breaker margin-top4">
        <div className="custom-flex-gap-4 items-container">
          <div className=" custom-flex">
            <figure>
              <img
                src={`/img/smartsheet/Gartner-Peer.png`}
                alt="C.Digital logo"
              />
            </figure>
          </div>
          <div className="  custom-flex">
            <figure>
              <span className="text-center text-large text-black font-weight-600 display-block alt-font ">
                Smartsheet has been ranked the highest & named a Leader in the
                Gartner® Magic Quadrant™ <br /> for Collaborative Work
                Management.
              </span>
            </figure>
          </div>
          <div className=" custom-flex">
            <NavLink
              target="_blank"
              to="https://www.gartner.com/doc/reprints?id=1-2FU47JBZ&ct=231206&st=sb&utm_content=button1&mkt_tok=NDY0LU9OTS0xNDkAAAGQL0fd2CJH2i7AprmgEavKOl-FigbDSfoXbn-JZ9ZR-mPanImS1Ky8uimlI61OSsQgKZ2jPlXldJ8tne-GWMZ9MlgScgxOjCisydYBjGoe0CVaTifsqOE
                  "
            >
              Read the Report
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GartnerInsight;
