import React from "react";
import { NavLink } from "react-router-dom";

const ROI = () => {
  return (
    <div className="roi-bg" id="smartsheet">
      {/* <div className=" right custom-flex">
        <figure>
          <img
            style={{ width: "100vw" }}
            src={`/img/smartsheet/graybg.jpg`}
            alt="C.Digital logo"
          />
        </figure>
      </div> */}
      <section className="roi custom-flex">
        <div
          className="row items-container custom-container custom-flex"
          style={{ paddingBlock: "2rem" }}
        >
          <div className="col-9 custom-flex">
            <figure>
              <h5 className="text-center text-white">
                Uncover the ROI of Smartsheet Project and Portfolio Management
              </h5>
              <span className="text-center text-white  text-medium display-block  margin-10px-bottom">
                A remarkable 680% ROI stands as a testament to Smartsheet's
                effectiveness. The recent Forrester Consulting research titled
                "The Total Economic Impact of Smartsheet" illustrates how the
                platform facilitates successful business transformation by
                empowering organizations to efficiently manage projects,
                automate manual tasks and workflows, and swiftly create
                innovative solutions.
              </span>
            </figure>
          </div>
          <div className="col-3 left custom-flex">
            <NavLink
              target="_blank"
              to="https://www.smartsheet.com/sites/default/files/2022-01/TEI_of_Smartsheet_January_2022.pdf
                  "
            >
              <img style={{width:"14px",marginRight:"4px"}} src="/img/smartsheet/icondownload.png" alt="download icon" />
              REPORT
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ROI;
