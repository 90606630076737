import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoComponent from "./VideoComponent";

const Animation = () => {

 



  return (
    <div id="smartsheet">
      <div className="filter-container">
        {/* <div className="row">
        <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 center-col margin-two-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
          <h5 className="section-main-heading fadeIn font-weight-500">
            Smartsheet Integration
          </h5>
        </div>
      </div> */}
        <div className="desktop_view custom-container">
          <div className="row tab-col">
            <div className="col-8 tab-video">
              <div className=" animation">
                {/* <figure className="video media-foreground paragraph" data-loop="loop-once">
                <video
                  autoPlay
                  muted
                  // width={1350}
                  // height={750}
                  className="js-playing"
                  playsInline
                  controls={false} // Remove video controls
                  onContextMenu={(e) => e.preventDefault()} // Prevent right-click context menu
                  onDoubleClick={(e) => e.preventDefault()} // Prevent double-click behavior
                >
                  <source
                    src="https://www.smartsheet.com/sites/default/files/2022-05/Smartsheet-Integrations-Q2-2022.mp4"
                    type="video/mp4"
                  />
                </video>
              </figure> */}

                <VideoComponent />
              </div>
            </div>
            <div className="col-4 integration_content">
              <h4 className="formI text-black">Integrations</h4>
              <p className="formI text-medium">
                Through existing integrations, the Smartsheet platform can
                connect with Adobe Creative Cloud, Slack, Google Workspace,
                Jira, and more. And Smartsheet is the only work management
                platform that combines the capabilities of digital asset
                management and resource management in a single solution.
              </p>
            </div>
          </div>
        </div>
        <div className="mobile_view">
          <div className="row ">
            <div className="col-4 integration_content">
              <h2 className="form text-black">Integrations</h2>
              <p className="formI text-medium">
                Through existing integrations, the Smartsheet platform can
                connect with Adobe Creative Cloud, Slack, Google Workspace,
                Jira, and more. And Smartsheet is the only work management
                platform that combines the capabilities of digital asset
                management and resource management in a single solution.
              </p>
            </div>
            <div className="col-8">
              <div className=" animation">
                <figure
                  className="video media-foreground paragraph"
                  data-loop="loop-once"
                >
                  <video
                    autoPlay
                    muted
                    // width={1350}
                    // height={750}
                    className="js-playing"
                    playsInline
                    controls={false} // Remove video controls
                    onContextMenu={(e) => e.preventDefault()} // Prevent right-click context menu
                    onDoubleClick={(e) => e.preventDefault()} // Prevent double-click behavior
                  >
                    <source
                      src="https://www.smartsheet.com/sites/default/files/2022-05/Smartsheet-Integrations-Q2-2022.mp4"
                      type="video/mp4"
                    />
                  </video>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animation;
