import React from "react";
// import TrySmartsheetNow from './TrySmartsheetNow'
import { NavLink } from "react-router-dom";
const Slider = () => {
  return (
    <section className="crm-digital-container" id="smartsheet">
      <div className="banner1">
        <div className="desktop">
          <div className="row first-content custom-flex ">
            <div className="col-4 custom-flex-col-gap content-block text-extra-dark-gray">
              <h5 className="">
                {" "}
                Elevate Your Business with{" "}
                <span className="slider-span">Smartsheet</span>{" "}
              </h5>
              <p>
                Enable seamless collaboration and flexibility by bringing
                together the right individuals, processes, and information onto
                a unified platform using Smartsheet
              </p>
              <div className="btn-dual">
                <NavLink
                  to={`/contact/`}
                  target="_blank"
                  className="get_started"
                >
                  Book A Demo
                </NavLink>

                <NavLink
                  to={
                    "https://app.smartsheet.com/b/form/e8a0bdfcc92245baae58df237fe5d5ac"
                  }
                  target="_blank"
                  className="free_trial"
                >
                  Start Free Trial
                </NavLink>
              </div>
            </div>
            <div className="col-8 img-block" style={{ outline: "none" }}>
              <video
                style={{ outline: "none" }}
                width="100%"
                height="auto"
                autoPlay
                muted
                loop
                id="home-video"
              >
                <source src={"/video/Smartsheet_last.mp4"} type={"video/mp4"} />
              </video>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="row first-content ">
            <div className=" img-block video-block">
              <video
                width="100%"
                height="auto"
                autoPlay
                muted
                loop
                id="home-video"
              >
                <source src={"/video/Smartsheet_last.mp4"} type={"video/mp4"} />
              </video>
           
            </div>
            <div className=" content-block">
              <h5 className="">
                {" "}
                Elevate Your Business with{" "}
                <span className="slider-span">Smartsheet</span>{" "}
              </h5>

              <p>
                {" "}
                Enable seamless collaboration and flexibility by bringing
                together the right individuals, processes, and information onto
                a unified platform using Smartsheet
              </p>
              <div className="btn-dual">
                <NavLink
                  to={`/contact/`}
                  target="_blank"
                  className="get_started"
                >
                  Book A Demo
                </NavLink>

                <NavLink
                  to={`/contact/`}
                  target="_blank"
                  className="free_trial"
                >
                  Start Free Trial
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
