import React, { useEffect } from "react";
import "./css/module.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
// import Nav from './Nav'
import Sidebar from "../../pages/Sidebar/Sidebar";
import Slider from "./Components/Slider";
import Aboutlegacy from "./Components/Aboutlegacy";
import Whychooseus from "./Components/Whychooseus";
import TabsAnimation from "./Components/TabsAnimation";
import Percentage from "./Components/Percentage";
import RubberStudio from "./Components/RubberStudio";
import CreativePeople from "./Components/CreativePeople";
import Blogs from "./Components/Blogs";
import Purchase from "./Components/Purchase";
import PremiumBanner from "./Components/PremiumBanner";
import Animation from "./Components/Animation";
import ROI from "./Components/ROI";
import Client from "./Comp/Client";
import AboutVirtuos from "./Comp/AboutVirtuos";
import Casestudy from "./Components/Casestudy";
import GartnerInsight from "./Comp/GartnerInsight";

// import styles from "./css/styles.module.css";
const Smartsheet = () => {
  // useEffect(() => {
  //   const scriptElements = [
  //     "jquery.js",
  //     "modernizr.js",
  //     "bootstrap.min.js",
  //     "jquery.easing.1.3.js",
  //     "skrollr.min.js",
  //     "smooth-scroll.js",
  //     "jquery.appear.js",
  //     "bootsnav.js",
  //     "jquery.nav.js",
  //     "wow.min.js",
  //     "page-scroll.js",
  //     "swiper.min.js",
  //     "jquery.count-to.js",
  //     "jquery.stellar.js",
  //     "jquery.magnific-popup.min.js",
  //     "isotope.pkgd.min.js",
  //     "imagesloaded.pkgd.min.js",
  //     "classie.js",
  //     "hamburger-menu.js",
  //     "counter.js",
  //     "jquery.fitvids.js",
  //     "equalize.min.js",
  //     "skill.bars.jquery.js",
  //     "justified-gallery.min.js",
  //     "jquery.easypiechart.min.js",
  //     "instafeed.min.js",
  //     "retina.min.js",
  //     "jquery.themepunch.tools.min.js",
  //     "jquery.themepunch.revolution.min.js",
  //   ];

  //   scriptElements.forEach((src) => {
  //     const script = document.createElement("script");
  //     script.src = `/js/${src}`;
  //     script.async = true;
  //     document.body.appendChild(script);
  //   });

  //   return () => {
  //     // Cleanup the dynamically added script elements when the component unmounts
  //     scriptElements.forEach((src) => {
  //       const script = document.querySelector(`script[src="/js/${src}"]`);
  //       if (script) {
  //         document.body.removeChild(script);
  //       }
  //     });
  //   };
  // }, []);
  return (
    <div className="home-page">
      <div className="canvas">
        <Navbar />
        <main className="main-wrapper ">
          <div id="">
            {/* end header */}
            {/* start slider */}
            <Slider />
            {/* end slider */}
            {/* start about agency */}
            {/* <Aboutlegacy /> */
            <GartnerInsight/>}
            <AboutVirtuos/>

            {/* <Client/> */}
            <Percentage />

            {/* end about agency section */}
            {/* start feature box section */}

            <Whychooseus />
            <ROI />

            {/* start feature box section */}
            {/* start portfolio section */}
            <Animation />

            {/* SMARTSHEET INTEGRATION */}
            {/* <TabsAnimation /> */}
            {/* end portfolio section */}
            {/* start pie chart section */}
            {/* end pie chart section */}
            {/* start services section */}
            {/* <RubberStudio /> */}
            <Casestudy/>
            {/* Case Studies */}
            {/* end services section */}
            {/* start team section */}
            {/* <CreativePeople /> */}

            {/* end team section */}
            {/* start explore work section */}
            {/* <PremiumBanner /> */}
            {/* end explore work section */}
            {/* start blog section */}
            {/* <Blogs /> */}
            {/* end blog section */}
            {/* start call to action section */}
            <Purchase />
            {/* end call to action section */}
            {/* start footer */}
            {/* end footer */}
            {/* start scroll to top */}
            <a className="scroll-top-arrow" href="javascript:void(0);">
              <i className="ti-arrow-up" />
            </a>
            {/* end scroll to top  */}
            {/* javascript libraries */}
            {/* menu navigation */}
            {/* animation */}
            {/* page scroll */}
            {/* swiper carousel */}
            {/* counter */}
            {/* parallax */}
            {/* magnific popup */}
            {/* portfolio with shorting tab */}
            {/* images loaded */}
            {/* pull menu */}
            {/* counter  */}
            {/* fit video  */}
            {/* equalize */}
            {/* skill bars  */}
            {/* justified gallery  */}
            {/*pie chart*/}
            {/* instagram */}
            {/* retina */}
            {/* revolution */}
            {/* revolution slider extensions (load below extensions JS files only on local file systems to make the slider work! The following part can be removed on server for on demand loading) */}
            {/**/}
            {/* setting */}
          </div>
          <Footer />
        </main>
      </div>
      <div className="off-canvas">
        <Sidebar />
      </div>
    </div>
  );
};

export default Smartsheet;
